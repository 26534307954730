import {
  ArchiveBoxIcon,
  ArrowDownTrayIcon,
  BanknotesIcon,
  BuildingOffice2Icon,
  CircleStackIcon,
  CurrencyEuroIcon,
  GlobeEuropeAfricaIcon,
  InformationCircleIcon,
  ShoppingBagIcon,
  StarIcon,
  TruckIcon,
  UserGroupIcon,
  UserIcon,
  UsersIcon,
  VideoCameraIcon,
} from '@heroicons/vue/24/outline';
import {Component} from 'vue';
import {RouteLocationRaw} from 'vue-router';
import {useWorkspaceStore} from '@/stores/workspace';

export interface TopbarItem {
  icon?: Component;
  url: RouteLocationRaw;
  name: string;
  children?: TopbarItem[];
  allowedGroups: string[];
  allowedPermissions?: {name: string; values: string[]}[];
}

export interface TopbarMenu {
  label?: string;
  items: TopbarItem[];
  allowedGroups: string[];

  allowedPermissions?: {name: string; values: string[]}[];
}

export const getMainMenu = (): TopbarMenu[] => {
  const {isOnlyBar} = useWorkspaceStore();
  const menu = [
    {
      allowedGroups: ['admin', 'city_manager'],
      items: [
        {
          icon: ShoppingBagIcon,
          url: {name: !isOnlyBar ? 'order.index' : 'order.bar-checkout.index'},
          name: 'Orders',
          allowedGroups: ['admin', 'city_manager'],
        },
        {
          icon: BuildingOffice2Icon,
          url: {name: 'store.index'},
          name: 'Stores',
          allowedGroups: ['admin', 'city_manager'],
        },
        {
          icon: ArchiveBoxIcon,
          url: {name: 'product.index'},
          name: 'Prodotti',
          allowedGroups: ['admin', 'city_manager'],
        },
        {
          icon: UsersIcon,
          url: {name: 'user.index'},
          name: 'Users',
          allowedGroups: ['admin', 'city_manager'],
          allowedPermissions: [{name: 'stores', values: ['5c000c12c8a4a7002f8a023f']}],
        },
        {
          icon: UserIcon,
          url: {name: 'cityManagers'},
          name: 'City managers',
          allowedGroups: ['admin'],
        },
        {
          icon: UserGroupIcon,
          url: {name: 'driver.index'},
          name: 'Drivers',
          allowedGroups: ['admin', 'city_manager'],
        },
        {
          icon: TruckIcon,
          url: {name: 'supplier.index'},
          name: 'Fornitori',
          allowedGroups: ['admin'],
        },
        {
          icon: CurrencyEuroIcon,
          url: {name: 'credits.coupons.index'},
          name: 'Crediti',
          allowedGroups: ['admin'],
        },
        {
          icon: StarIcon,
          url: {name: 'loyalty.redeems'},
          name: 'Loyalty',
          allowedPermissions: [{name: 'stores', values: ['5c000c12c8a4a7002f8a023f']}],
          allowedGroups: ['admin', 'city_manager'],
        },
        {
          icon: GlobeEuropeAfricaIcon,
          url: {name: 'experience.orders'},
          name: 'Esperienze',
          allowedGroups: ['admin'],
        },
        {
          icon: VideoCameraIcon,
          url: {name: 'tv.video.index'},
          name: 'Winelivery TV',
          allowedGroups: ['admin'],
        },
        {
          icon: InformationCircleIcon,
          url: {name: 'alerts'},
          name: 'Alerts',
          allowedGroups: ['admin'],
        },
        {
          icon: CircleStackIcon,
          url: {name: 'cms.landings.index'},
          name: 'CMS',
          allowedGroups: ['admin'],
        },
        {
          icon: BanknotesIcon,
          url: {name: 'accounting.coreFees'},
          name: 'Accounting',
          allowedGroups: ['admin'],
        },
        {
          icon: ArrowDownTrayIcon,
          url: {name: 'knowledgeBase.index'},
          name: 'Knowledge Base',
          allowedGroups: ['admin', 'city_manager'],
        },
      ],
    },
  ];

  return menu;
};
