<template>
  <div v-if="props.loading" class="absolute inset-0 z-30 bg-slate-50/50">
    <div class="flex h-full w-full items-center justify-center">
      <ScaleLoader color="rgb(255 34 84)" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue';

interface Props {
  loading: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  loading: false,
});
</script>
