<template>
  <div class="flex h-[calc(100dvh)] antialiased bg-white dark:bg-gray-800">
    <RouterView v-if="store.ready" class="flex-1" />
  </div>
</template>

<script setup lang="ts">
import { useAppStore } from '@/stores/app';
// import { datadogRum } from '@datadog/browser-rum';

// if (!import.meta.env.DEV) {
//   datadogRum.init({
//     applicationId: 'b055706d-de78-4f59-99fd-791d40f5b18a',
//     clientToken: 'pub7cf1f77bfc047465e03fafef5d0e32d2',
//     site: 'datadoghq.eu',
//     service: 'wl-backoffice',
//     env: 'production',
//     // Specify a version number to identify the deployed version of your application in Datadog
//     // version: '1.0.0',
//     sessionSampleRate: 100,
//     sessionReplaySampleRate: 0,
//     trackUserInteractions: true,
//     trackResources: true,
//     trackLongTasks: true,
//     allowedTracingUrls: ['https://api.eu1.winelivery.com', 'https://apis.winelivery.com'],
//     defaultPrivacyLevel: 'mask-user-input',
//   });
// }

const store = useAppStore();
</script>
