<template>
  <Popover v-slot="{ open }" class="relative">
    <PopoverButton
      :class="[open ? 'ring-2 ring-secondary-light' : 'text-opacity-90']"
      class="rounded-full cursor-pointer focus:outline-none"
      as="div"
    >
      <Avatar
        :size="32"
        variant="beam"
        :name="`${client.getIdentity()?.name} ${client.getIdentity()?.lastname}`"
      />
    </PopoverButton>
    <transition
      enter-active-class="transition duration-200 ease-out"
      enter-from-class="translate-y-1 opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transition duration-150 ease-in"
      leave-from-class="translate-y-0 opacity-100"
      leave-to-class="translate-y-1 opacity-0"
    >
      <div>
        <PopoverPanel
          class="absolute -right-2 z-20 mt-2 w-screen max-w-xs bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
        >
          <div class="p-3">
            <h6 class="font-medium">
              {{ client.getIdentity()?.name }} {{ client.getIdentity()?.lastname }}
            </h6>
            <div class="text-sm text-gray-500 tracking-tight">
              {{ client.getIdentity()?.email }}
            </div>
            <div class="text-xs pt-1 text-gray-400 tracking-tight">
              {{ client.getIdentity()?.group === 'city_manager' ? 'City Manager' : 'Admin' }}
            </div>
          </div>
          <hr class="mx-3" />
          <a class="flex items-center p-3" href="#" @click="handleLogout">
            <ArrowLeftOnRectangleIcon class="h-5 mr-3" />
            <span>Esci</span>
          </a>
        </PopoverPanel>
      </div>
    </transition>
  </Popover>
</template>

<script lang="ts" setup>
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { ArrowLeftOnRectangleIcon, Cog6ToothIcon, UserIcon } from '@heroicons/vue/24/outline';
import { useWlClient } from '@/plugins/wl-client';
import { useRouter } from 'vue-router';
import Cookies from 'js-cookie';
import Avatar from 'vue-boring-avatars';
import { useProductsRequestsStore } from '@/stores/products-requests';
import { useProductsStore } from '@/stores/products';
import { useInventoryState } from '@/stores/inventory';
import { useWorkspaceStore } from '@/stores/workspace';
import { useOrdersState } from '@/stores/orders';

const client = useWlClient();
const router = useRouter();

const productsRequests = useProductsRequestsStore();
const products = useProductsStore();
const inventory = useInventoryState();
const workspace = useWorkspaceStore();
const orders = useOrdersState();

const handleLogout = () => {
  client.setTokens({ accessToken: '', refreshToken: '' });
  Cookies.remove('__refresh_token__');

  productsRequests.$reset();
  products.$reset();
  inventory.$reset();
  workspace.$reset();
  orders.$reset();

  router.push({ name: 'auth.sign_in' });
};
</script>
