import { formatError } from '@/utils';
import { defineStore } from 'pinia';
import { useAppStore } from './app';
import sdk from '@/plugins/wl-client';
import { AvailableFilter, SelectedFilter } from '@/components/base/filters/types';
import {
  GetInventoryStoreProductsParams,
  InventoryStoreProduct,
} from '@winelivery-org/wl-client-sdk-js';

const INVENTORY_STORE_ID = 'inventory';

export type FilterKeys = 'sort';

interface State {
  storeId: string;
  filters: GetInventoryStoreProductsParams;
  availableFilters: AvailableFilter<FilterKeys>[];
  selectedFilters: SelectedFilter<FilterKeys>[];
  products: InventoryStoreProduct[];
  count: number;
}

export const useInventoryState = defineStore(INVENTORY_STORE_ID, {
  state: (): State => ({
    storeId: '',
    filters: { page: 1, limit: 50 },
    selectedFilters: [],
    availableFilters: [
      {
        key: 'sort',
        label: 'Ordina',
        type: 'select',
        options: [
          { label: 'Rank alto', value: 'rank:desc' },
          { label: 'Rank basso', value: 'rank:asc' },
          { label: 'Più recente', value: 'createdAt:desc' },
          { label: 'Meno recente', value: 'createdAt:asc' },
          { label: 'Aggiornamento più recente', value: 'updatedAt:desc' },
          { label: 'Aggiornamento meno recente', value: 'updatedAt:asc' },
          { label: 'Stock alto', value: 'stock:desc' },
          { label: 'Stock basso', value: 'stock:asc' },
        ],
      },
    ],
    count: 0,
    products: [],
  }),
  getters: {
    getFilters: (state) => (): GetInventoryStoreProductsParams => {
      const sort = state.selectedFilters.find((f) => f.key === 'sort');
      return {
        ...state.filters,
        ...(sort && { sort: sort.value as string }),
      };
    },
  },
  actions: {
    setStoreId(storeId: string) {
      this.storeId = storeId;
      this.getMany();
    },
    setPage(page: number) {
      this.filters.page = page;
      this.getMany();
    },
    setSearchQuery(search: string) {
      this.filters.q = search;
      this.filters.page = 1;
      this.getMany();
    },
    reset() {
      this.filters = { page: 1, limit: 50 };
      this.getMany();
    },
    applyFilter(key: FilterKeys, value: string | number) {
      const index = this.selectedFilters.findIndex((f) => f.key === key);
      if (index !== -1) {
        this.selectedFilters[index] = { key, value };
      } else {
        this.selectedFilters.push({ key, value });
      }
      this.getMany();
    },
    resetFilter(key: FilterKeys) {
      this.selectedFilters = this.selectedFilters.filter((f) => f.key !== key);
      this.getMany();
    },
    async getMany() {
      const app = useAppStore();
      try {
        app.loading = true;
        const response = await sdk.inventory.getStoreProducts(this.storeId, this.getFilters());
        this.products = response.items;
        this.count = response.count;
      } catch (err) {
        formatError(err);
      } finally {
        app.loading = false;
      }
    },
  },
});
