import { createApp } from 'vue';
import { createPinia } from 'pinia';
import router from './router';
import i18n from './i18n';
import Toast from 'vue-toastification';
import wlClient from './plugins/wl-client';
import FloatingVue from 'floating-vue';
import App from './App.vue';
import './assets/scss/index.scss';
import 'vue-toastification/dist/index.css';
import 'floating-vue/dist/style.css';

createApp(App)
  .use(createPinia())
  .use(router)
  .use(i18n)
  .use(wlClient)
  .use(FloatingVue)
  .use(Toast, {})
  .mount('#app');
