import {defineStore} from 'pinia';
import {InventoryStore} from '@winelivery-org/wl-client-sdk-js';
import wlClient from '@/plugins/wl-client';

const WORKSPACE_STORE_ID = 'workspace';

interface State {
  stores: InventoryStore[];
  isOnlyBar: boolean;
}

export const useWorkspaceStore = defineStore(WORKSPACE_STORE_ID, {
  state: (): State => ({
    stores: [],
    isOnlyBar: false,
  }),
  getters: {
    currentStore: (state) => (storeId: string) => {
      return state.stores.find(({id}) => id === storeId);
    },
    getStoreById: (state) => (storeId: string) => {
      return state.stores.find(({id}) => id === storeId);
    },
  },
  actions: {
    async init() {
      const identity = wlClient.getIdentity();
      if (!identity) {
        return;
      }
      const {items} = await wlClient.inventory.getStores({
        limit: 200,
      });
      this.stores = items.sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase()));
      this.isOnlyBar =
        items.filter((s) => s.tags.includes('bar') || s.tags.includes('take_away')).length ===
        items.length;
    },
  },
});
