import {AvailableFilter, SelectedFilter} from '@/components/base/filters/types';
import sdk from '@/plugins/wl-client';
import router from '@/router';
import {useWorkspaceStore} from '@/stores/workspace';
import {formatError} from '@/utils';
import {StoreAZ} from '@winelivery-org/wl-client-sdk-js';
import {
  GetManyOrderQuery,
  Order,
  PatchOrder,
} from '@winelivery-org/wl-client-sdk-js/dist/models/apis/orders';
import {Driver} from '@winelivery-org/wl-client-sdk-js/dist/models/driver';
import {DateTime} from 'luxon';
import {defineStore} from 'pinia';
import {useAppStore} from './app';

const ORDERS_STORE_ID = 'orders';

export type OrderfilterKeys = 'status' | 'store' | 'orderType' | 'driver' | 'has_invoice';

interface State {
  filters: GetManyOrderQuery;
  availableFilters: AvailableFilter<OrderfilterKeys>[];
  selectedFilters: SelectedFilter<OrderfilterKeys>[];
  orders: Order[];
  order?: Order;
  count: number;
  drivers: Driver[];
  defcon: {storeName: string; azs: StoreAZ[]}[];
}

const initDefcon = () => {
  const item = localStorage.getItem('defcon');
  if (!item) {
    return [];
  }
  const defcon = JSON.parse(item) as {storeName: string; azs: StoreAZ[]}[];
  const results = defcon.filter((d) => {
    const azs = d.azs.filter((az) => {
      const expiresAt = az.estimatedDeliveryTimeOverwrite.expiresAt;
      return expiresAt && DateTime.fromISO(expiresAt) < DateTime.now();
    });
    return azs.length > 0;
  });
  if (results.length === 0) {
    localStorage.removeItem('defcon');
  }
  return defcon;
};

const initSelectedFilters = (): SelectedFilter<OrderfilterKeys>[] => {
  const {query} = router.currentRoute.value;
  const filters: SelectedFilter<OrderfilterKeys>[] = [
    {key: 'status', value: parseInt(query.status as string) || 0},
  ];
  if (query.store) {
    filters.push({
      key: 'store',
      value: query.store as string,
    });
  }
  if (query.orderType) {
    filters.push({
      key: 'orderType',
      value: query.orderType as string,
    });
  }
  if (query.driver) {
    filters.push({
      key: 'driver',
      value: query.driver as string,
    });
  }
  if (query.has_invoice) {
    filters.push({
      key: 'has_invoice',
      value: query.has_invoice as string,
    });
  }
  return filters;
};

export const useOrdersState = defineStore(ORDERS_STORE_ID, {
  state: (): State => ({
    defcon: initDefcon(),
    filters: {page: 1},
    selectedFilters: initSelectedFilters(),
    availableFilters: [
      {
        key: 'status',
        label: 'Status',
        type: 'select',
        options: [
          {value: 0, label: 'Pending'},
          {value: 4, label: 'Accepted'},
          {value: 1, label: 'Shipping'},
          {value: 2, label: 'Completed'},
          {value: 3, label: 'Canceled'},
        ],
      },
      {
        key: 'store',
        label: 'Store',
        type: 'select',
        options: useWorkspaceStore().stores.map((s) => ({value: s.id, label: s.name})),
      },
      {
        key: 'orderType',
        label: 'Consegna',
        type: 'select',
        options: [
          {value: 'normal', label: 'Express'},
          {value: 'hinterland', label: 'Hinterland'},
          {value: 'late', label: 'Consegnare adesso'},
          {value: 'delivering', label: 'Consegnare oggi'},
        ],
      },
      {
        key: 'has_invoice',
        label: 'Fattura',
        type: 'select',
        options: [
          {value: 'true', label: 'Sì'},
          {value: 'false', label: 'No'},
        ],
      },
    ],
    count: 0,
    orders: [],
    drivers: [],
  }),
  getters: {
    getFilters: (state) => () => {
      const status = state.selectedFilters.find((f) => f.key === 'status');
      const store = state.selectedFilters.find((f) => f.key === 'store');
      const orderType = state.selectedFilters.find((f) => f.key === 'orderType');
      const driver = state.selectedFilters.find((f) => f.key === 'driver');
      const has_invoice = state.selectedFilters.find((f) => f.key === 'has_invoice');

      return {
        ...state.filters,
        ...(status && {status: status.value as string}),
        ...(store && {store: store.value as string}),
        ...(orderType && {orderType: orderType.value as string}),
        ...(driver && {driver: driver.value as string}),
        ...(has_invoice && {has_invoice: has_invoice.value as 'true' | 'false'}),
      };
    },
  },
  actions: {
    setDefcon(params: {storeName: string; azs: StoreAZ[]}) {
      const match = this.defcon.find((d) => d.storeName === params.storeName);
      if (!match) {
        this.defcon.push(params);
      } else {
        this.defcon = this.defcon.map((d) => (d.storeName === params.storeName ? params : d));
      }
      localStorage.setItem('defcon', JSON.stringify(this.defcon));
    },
    removeDefcon(storeName: string) {
      this.defcon = this.defcon.filter((d) => d.storeName !== storeName);
      localStorage.setItem('defcon', JSON.stringify(this.defcon));
    },
    setPage(page: number) {
      this.filters.page = page;
    },
    setSearch({search, field}: {search: string; field?: string}) {
      this.resetSearch();
      if (field === 'userId') return (this.filters.userId = search);
      if (field === 'email') return (this.filters.email = search);
      if (field === 'address') return (this.filters.address = search);
    },
    resetSearch() {
      this.filters.userId = undefined;
      this.filters.email = undefined;
      this.filters.address = undefined;
    },
    applyFilter(key: OrderfilterKeys, value: string | number) {
      const index = this.selectedFilters.findIndex((f) => f.key === key);

      const query = router.currentRoute.value.query;
      router.push({query: {...query, [key]: value}});

      if (index !== -1) {
        this.selectedFilters[index] = {key, value};
      } else {
        this.selectedFilters.push({key, value});
      }

      this.getMany();

      if (key === 'store') {
        this.getDrivers();
      }
    },
    resetFilter(key: OrderfilterKeys) {
      this.selectedFilters = this.selectedFilters.filter((f) => f.key !== key);
      router.replace({query: {...router.currentRoute.value.query, [key]: undefined}});
      this.getMany();
    },
    async getMany() {
      const app = useAppStore();
      try {
        app.loading = true;
        const response = await sdk.wlapi.getOrders(this.getFilters());
        this.orders = response.orders;
        this.count = response.count;
      } catch (err) {
        formatError(err);
      } finally {
        app.loading = false;
      }
    },
    async getDrivers(store?: string) {
      try {
        /**
         * Admins must select a store to fetch the available drivers
         */
        store = store || this.getFilters().store;
        const isAdmin = sdk.getIdentity()?.group === 'admin';
        if (!isAdmin || (store && store.length > 0)) {
          const response = await sdk.driver.getMany({
            ...(store && {stores: [store]}),
            active: 'true',
          });
          this.drivers = response.drivers;
        }

        this.availableFilters = this.availableFilters.filter((f) => f.key !== 'driver');
        this.availableFilters.push({
          key: 'driver',
          label: 'Driver',
          type: 'select',
          options: this.drivers.map((d) => ({
            value: d._id,
            label: `${d.firstname.toUpperCase()} ${d.lastname.toUpperCase()}`,
          })),
        });
      } catch (err) {
        formatError(err);
      }
    },
    async update(orderId: string, patch: PatchOrder) {
      const app = useAppStore();
      try {
        app.loading = true;
        const order = await sdk.wlapi.patchOrder(orderId, patch);
        this.orders = this.orders.map((o) => (o._id === order._id ? order : o));
      } catch (err) {
        formatError(err);
      } finally {
        app.loading = false;
      }
    },
    async searchById(orderId: string) {
      const app = useAppStore();
      try {
        app.loading = true;
        const response = await sdk.wlapi.getOrderById(orderId);
        this.orders = [response.order];
        this.count = 1;
      } catch (err) {
        formatError(err);
      } finally {
        app.loading = false;
      }
    },
    async getById(orderId: string) {
      const app = useAppStore();
      try {
        app.loading = true;
        const result = await sdk.wlapi.getOrderById(orderId);
        return result.order;
      } catch (err) {
        formatError(err);
      } finally {
        app.loading = false;
      }
    },
  },
});
