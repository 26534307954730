import { formatError } from '@/utils';
import { defineStore } from 'pinia';
import { useAppStore } from './app';
import sdk from '@/plugins/wl-client';
import { AvailableFilter, SelectedFilter } from '@/components/base/filters/types';
import { GetInventoryProductsParams, InventoryProduct } from '@winelivery-org/wl-client-sdk-js';

const PRODUCTS_STORE_ID = 'products';

export type FilterKeys = 'sort' | 'categories' | 'suppliers' | 'isExpress' | 'isEcommerce';

interface State {
  filters: GetInventoryProductsParams;
  availableFilters: AvailableFilter<FilterKeys>[];
  selectedFilters: SelectedFilter<FilterKeys>[];
  products: InventoryProduct[];
  count: number;
}

export const useProductsStore = defineStore(PRODUCTS_STORE_ID, {
  state: (): State => ({
    filters: { page: 1, limit: 50 },
    selectedFilters: [{ key: 'sort', value: 'createdAt:desc' }],
    availableFilters: [
      {
        key: 'sort',
        label: 'Ordina',
        type: 'select',
        options: [
          { label: 'Rank alto', value: 'rank:desc' },
          { label: 'Rank basso', value: 'rank:asc' },
          { label: 'Prezzo alto', value: 'price:desc' },
          { label: 'Prezzo basso', value: 'price:asc' },
          { label: 'Più recente', value: 'createdAt:desc' },
          { label: 'Meno recente', value: 'createdAt:asc' },
          { label: 'Aggiornamento più recente', value: 'updatedAt:desc' },
          { label: 'Aggiornamento meno recente', value: 'updatedAt:asc' },
        ],
      },
      {
        key: 'categories',
        label: 'Categoria',
        type: 'select',
        options: [],
      },
      {
        key: 'suppliers',
        label: 'Fornitore',
        type: 'select',
        options: [],
      },
      {
        key: 'isExpress',
        label: 'Express',
        type: 'select',
        options: [
          { label: 'SI', value: 'true' },
          { label: 'NO', value: 'false' },
        ],
      },
      {
        key: 'isEcommerce',
        label: 'Ecommerce',
        type: 'select',
        options: [
          { label: 'SI', value: 'true' },
          { label: 'NO', value: 'false' },
        ],
      },
    ],
    count: 0,
    products: [],
  }),
  getters: {
    getFilters: (state) => (): GetInventoryProductsParams => {
      const sort = state.selectedFilters.find((f) => f.key === 'sort');
      const categories = state.selectedFilters.find((f) => f.key === 'categories');
      const suppliers = state.selectedFilters.find((f) => f.key === 'suppliers');
      const isExpress = state.selectedFilters.find((f) => f.key === 'isExpress');
      const isEcommerce = state.selectedFilters.find((f) => f.key === 'isEcommerce');

      return {
        ...state.filters,
        ...(sort && { sort: sort.value as string }),
        ...(categories && { categories: [categories.value.toString()] }),
        ...(suppliers && { suppliers: [suppliers.value.toString()] }),
        ...(isExpress && { isExpress: isExpress.value.toString() === 'true' }),
        ...(isEcommerce && { isEcommerce: isEcommerce.value.toString() === 'true' }),
      };
    },
  },
  actions: {
    async init() {
      const [suppliers, attributes] = await Promise.all([
        sdk.inventory.getSuppliers(),
        sdk.inventory.getAttributes(),
      ]);

      this.availableFilters = this.availableFilters.map((af) =>
        af.key === 'suppliers'
          ? {
              ...af,
              options: suppliers.items
                .map((s) => ({ label: s.name.toUpperCase(), value: s.id }))
                .sort((a, b) => a.label.localeCompare(b.label.toUpperCase())),
            }
          : af
      );

      this.availableFilters = this.availableFilters.map((af) =>
        af.key === 'categories'
          ? {
              ...af,
              options: attributes.items
                .filter((a) => a.type === 'category')
                .map((s) => ({ label: s.details[0].name.toUpperCase(), value: s.id }))
                .sort((a, b) => a.label.toUpperCase().localeCompare(b.label.toUpperCase())),
            }
          : af
      );
    },
    setPage(page: number) {
      this.filters.page = page;
      this.getMany();
    },
    setSearchQuery(search: string) {
      this.filters.q = search;
      this.filters.page = 1;
      this.getMany();
    },
    reset() {
      this.filters = { page: 1, limit: 50 };
      this.getMany();
    },
    applyFilter(key: FilterKeys, value: string | number) {
      const index = this.selectedFilters.findIndex((f) => f.key === key);
      if (index !== -1) {
        this.selectedFilters[index] = { key, value };
      } else {
        this.selectedFilters.push({ key, value });
      }
      this.getMany();
    },
    resetFilter(key: FilterKeys) {
      this.selectedFilters = this.selectedFilters.filter((f) => f.key !== key);
      this.getMany();
    },
    async getMany() {
      const app = useAppStore();
      try {
        app.loading = true;
        const response = await sdk.inventory.getProducts(this.getFilters());
        this.products = response.items;
        this.count = response.count;
      } catch (err) {
        formatError(err);
      } finally {
        app.loading = false;
      }
    },
  },
});
