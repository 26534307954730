<template>
  <div class="flex overflow-hidden bg-white">
    <div class="flex-1 flex flex-col overflow-hidden">
      <TheTopbar />
      <RouterView class="bg-gray-50 flex-1 overflow-y-auto" />
    </div>
    <LoadingOverlay :loading="store.loading" />
  </div>
</template>

<script setup lang="ts">
import LoadingOverlay from '@/components/base/LoadingOverlay.vue';
import TheTopbar from '@/components/TheTopbar/TheTopbar.vue';
import { useAppStore } from '@/stores/app';

const store = useAppStore();
</script>
