<template>
  <button @click="toggle">
    <Bars3Icon v-if="!app.isMenuVisible" class="h-8" />
    <XMarkIcon v-else class="h-8" />
  </button>
</template>

<script lang="ts" setup>
import {useAppStore} from '@/stores/app';
import {XMarkIcon, Bars3Icon} from '@heroicons/vue/24/outline';

const app = useAppStore();

const toggle = () => {
  app.isMenuVisible = !app.isMenuVisible;
};
</script>
