import {useToast} from 'vue-toastification';

export const formatError = (err: any) => {
  console.error(err);
  const toast = useToast();
  if (err instanceof Error) {
    toast.error(err.message);
  } else {
    toast.error(err);
  }
};

export const formatSuccess = (message: string) => {
  const toast = useToast();
  toast.success(message);
};

export const phoneRegex =
  /^\+\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;

export const copyToClipboard = (data: string) => {
  const toast = useToast();
  navigator.clipboard.writeText(data);
  toast.success('Copiato negli appunti');
};

export function downloadCsv(content: string, fileName: string) {
  const blob = new Blob([content], {type: 'text/csv'});
  const urlForDownload = window.URL.createObjectURL(blob);
  const linkElement = document.createElement('a'); // create hyperlink HTML element

  linkElement.href = urlForDownload;
  linkElement.download = fileName;
  linkElement.click();

  URL.revokeObjectURL(urlForDownload); // free memory
}

export function downloadJson(content: string, fileName: string) {
  const blob = new Blob([content], {type: 'application/json'});
  const urlForDownload = window.URL.createObjectURL(blob);
  const linkElement = document.createElement('a'); // create hyperlink HTML element

  linkElement.href = urlForDownload;
  linkElement.download = fileName;
  linkElement.click();

  URL.revokeObjectURL(urlForDownload); // free memory
}

export const isMobileScreen = () => {
  return screen.width < 768;
};
