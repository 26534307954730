<template>
  <div class="flex">
    <div class="flex-1 w-full lg:w-2/5 lg:max-w-3xl bg-white dark:bg-gray-800">
      <div class="flex flex-col justify-between h-full w-full max-w-md mx-auto px-8">
        <div class="py-8">
          <div class="flex items-center">
            <div class="-mx-2 lg:mx-0">
              <img class="visible dark:hidden h-10" src="@/assets/logo-light.png" alt="App logo" />
              <img class="hidden dark:visible h-10" src="@/assets/logo-dark.png" alt="App logo" />
            </div>
          </div>
        </div>
        <RouterView />
        <div class="py-8">
          <p class="text-center text-gray-600 text-xs">
            &copy;{{ new Date().getFullYear() }}, Winelivery, {{ $t('auth.all_rights_reserved') }}.
          </p>
        </div>
      </div>
    </div>
    <div
      class="hidden overflow-hidden flex-1 w-full lg:w-3/5 md:grid bg-gray-200 content-center justify-center"
      style="background-color: #fef6f6"
    >
      <img
        src="https://cdn.winelivery.com/public/website/claim_home.gif"
        class="object-cover hover:object-scale-down mx-auto my-auto"
        alt="Vini, birre e drink a casa tua, subito!"
        width="400"
      />
      <img
        class="lg:max-w-3xl object-cover hover:object-scale-down mx-auto my-auto"
        src="https://cdn.winelivery.com/public/website/home-image.png"
      />
    </div>
  </div>
</template>

<script lang="ts" setup></script>
