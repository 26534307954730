import { createI18n } from 'vue-i18n';
import en from './locales/en.json';
import it from './locales/it.json';

const i18n = createI18n({
  legacy: false,
  locale: import.meta.env.VITE_I18N_LOCALE || 'it',
  fallbackLocale: import.meta.env.VITE_I18N_FALLBACK_LOCALE || 'it',
  numberFormats: {
    it: {
      currency: {
        style: 'currency',
        currency: 'EUR',
      },
    },
    en: {
      currency: {
        style: 'currency',
        currency: 'EUR',
      },
    },
  },
  messages: {
    en,
    it,
  },
});

export default i18n;
