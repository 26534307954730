<template>
  <div class="flex items-center justify-between px-6 py-2 border-b border-gray-200">
    <div class="flex items-center gap-4">
      <TheTopbarHamburger v-if="isMobileScreen() && hasSidebar" />
      <img v-else src="@/assets/logo.png" alt="Logo" class="h-8" />
      <div class="border-r border-gray-200 h-8" />
      <TheTopbarMenu />
    </div>
    <div class="flex items-center gap-2">
      <!-- <TheTopbarNotification /> -->
      <TheTopbarAccount />
    </div>
  </div>
</template>

<script lang="ts" setup>
import TheTopbarMenu from '@/components/TheTopbar/TheTopbarMenu.vue';
import TheTopbarAccount from '@/components/TheTopbar/TheTopbarAccount.vue';
import TheTopbarNotification from '@/components/TheTopbar/TheTopbarNotification.vue';
import TheTopbarHamburger from '@/components/TheTopbar/TheTopbarHamburger.vue';
import {useRoute} from 'vue-router';
import {computed} from 'vue';
import {isMobileScreen} from '@/utils';

const route = useRoute();
const hasSidebar = computed(() => route.meta.hasSidebar);
</script>
