<template>
  <Popover v-slot="{open}" class="relative">
    <PopoverButton
      :class="open ? '' : 'text-opacity-90'"
      class="group inline-flex items-center rounded-md px-3 py-2 text-base font-medium text-gray-700 border border-gray-300 hover:bg-gray-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
    >
      <Squares2X2Icon class="h-5 mr-2" />
      <span>Menu</span>
    </PopoverButton>
    <transition
      enter-active-class="transition duration-200 ease-out"
      enter-from-class="translate-y-1 opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transition duration-150 ease-in"
      leave-from-class="translate-y-0 opacity-100"
      leave-to-class="translate-y-1 opacity-0"
    >
      <div>
        <PopoverPanel
          v-slot="{close}"
          class="absolute bg-white top-12 left-0 z-20 mt-2 w-screen max-w-xs px-4 sm:px-0 grid grid-cols-1 overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
        >
          <div class="p-4 bg-gray-50 border-r border-gray-200">
            <nav v-for="(menu, index) in getMainMenu()" :key="`menu-${index}`">
              <hr v-if="index > 0" class="my-3 border-gray-300" />

              <span
                v-if="menu.label"
                class="text-xs tracking-wide font-medium uppercase text-gray-600 text-left"
              >
                {{ menu.label }}
              </span>

              <template v-for="item in menu.items" :key="item.name">
                <a
                  v-if="
                    permissionAllowed(item.allowedGroups, item.allowedPermissions) &&
                    item.allowedGroups.includes(client.getIdentity()?.group || '')
                  "
                  href="#"
                  class="flex items-center p-3 rounded hover:bg-secondary-light"
                  :class="active == item ? 'bg-secondary-light text-secondary' : 'text-gray-600'"
                  @click.prevent="handleClickItem(item, close)"
                >
                  <Component :is="item.icon" class="h-5" />
                  <span class="flex-1 ml-3 font-medium text-sm">
                    {{ item.name }}
                  </span>
                  <ChevronRightIcon v-if="item.children" class="h-4" />
                </a>
              </template>
            </nav>
          </div>
        </PopoverPanel>
      </div>
    </transition>
  </Popover>
</template>

<script lang="ts" setup>
import {useWlClient} from '@/plugins/wl-client';
import {Popover, PopoverButton, PopoverPanel} from '@headlessui/vue';
import {ChevronRightIcon, Squares2X2Icon} from '@heroicons/vue/24/outline';
import {ref} from 'vue';
import {useRouter} from 'vue-router';
import {TopbarItem, getMainMenu} from './menu';

const client = useWlClient();
const router = useRouter();
const active = ref<TopbarItem>();

async function handleClickItem(item: TopbarItem, close: () => void) {
  if (!item.children) {
    await router.push(item.url);
    close();
    return;
  }
  active.value = item;
}

const permissionAllowed = (
  allowedGroups: string[],
  allowedPermissions?: {name: string; values: string[]}[]
) => {
  if (allowedGroups.includes('admin') && client.getIdentity()?.group === 'admin') {
    return true;
  }
  if (!allowedPermissions) {
    return true;
  }

  const permissions = client.getIdentity()?.permissions;
  if (!permissions || permissions.length === 0) {
    return false;
  }

  const permissionName = allowedPermissions[0]?.name;
  const checkName = permissions[0].name;
  const permissionValue = allowedPermissions[0]?.values[0];
  const checkValue = permissions[0]?.values;
  if (permissionName === checkName && checkValue?.includes(permissionValue)) {
    return true;
  }

  return false;
};
</script>
