<template>
  <div class="flex overflow-hidden">
    <div
      v-if="hasSidebar && app.isMenuVisible"
      class="sticky top-0 space-y-2 overflow-y-auto flex-shrink-0"
    >
      <div class="h-full pt-4">
        <div
          class="h-full flex flex-col border-r transition-all duration-300"
          :class="{'lg:w-64 w-48 px-8 pb-0': !collapsed, 'w-[75px] h-full': collapsed}"
        >
          <RouterLink
            v-for="item in items"
            :key="item.label"
            :to="item.path"
            :class="{'justify-center mx-4 py-2 mb-2': collapsed, 'px-4 -mx-5': !collapsed}"
            class="flex items-center gap-2 py-2 text-gray-600 rounded hover:bg-secondary-light mb-2"
            exact-active-class="bg-primary hover:bg-primary-light text-white font-medium"
          >
            <Component :is="item.component" class="h-5 w-5" />
            <div v-if="!collapsed" class="text-sm">
              {{ $t(item.label) }}
            </div>
          </RouterLink>
          <div
            class="flex flex-1 items-end pb-4"
            :class="{'justify-center': collapsed, 'justify-end': !collapsed}"
          >
            <ChevronLeftIcon
              v-if="!collapsed"
              class="h-9 hover:bg-secondary-light p-2 rounded text-gray-700 -mx-8"
              @click="toggle"
            />
            <ChevronRightIcon
              v-else
              class="h-9 hover:bg-secondary-light p-2 rounded text-gray-700"
              @click="toggle"
            />
          </div>
        </div>
      </div>
    </div>
    <RouterView class="flex-1" />
  </div>
</template>

<script lang="ts" setup>
import {computed, FunctionalComponent, ref} from 'vue';
import {useRoute} from 'vue-router';
import {ChevronRightIcon, ChevronLeftIcon} from '@heroicons/vue/24/outline';
import {useAppStore} from '@/stores/app';

export interface Item {
  path: string;
  label: string;
  component: FunctionalComponent;
}

interface Props {
  items: Item[];
}

const app = useAppStore();
const collapsed = ref(localStorage.getItem('sidebar-collapsed') == 'true');

const toggle = () => {
  collapsed.value = !collapsed.value;
  localStorage.setItem('sidebar-collapsed', collapsed.value.toString());
};

const route = useRoute();
const hasSidebar = computed(() => route.meta.hasSidebar);

defineProps<Props>();
</script>
