import wlClient from '@/plugins/wl-client';
import {useAppStore} from '@/stores/app';
import {useWorkspaceStore} from '@/stores/workspace';
import {createRouter, createWebHistory} from 'vue-router';
import routes from './routes';

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const store = useAppStore();
  const {stores, isOnlyBar} = useWorkspaceStore();
  if (!store.ready) {
    await store.init();
  }

  const identity = wlClient.getIdentity();
  const isAuth = wlClient.getRefreshToken();

  if (!to.meta.auth && isAuth) {
    return next({name: !isOnlyBar ? 'order.index' : 'order.bar-checkout.index'});
  }

  if (to.meta.auth && !isAuth) {
    return next({name: 'auth.sign_in'});
  }

  if (
    (to.name === 'order.bar.index' || to.name === 'order.bar-checkout.index') &&
    !stores.find((s) => {
      return s.tags.includes('bar') || s.tags.includes('take_away');
    })
  ) {
    return next({name: 'order.index'});
  }

  const groupDisallowed =
    to.meta.auth &&
    isAuth &&
    Array.isArray(to.meta.authorizedGroups) &&
    to.meta.authorizedGroups.length > 0 &&
    !to.meta.authorizedGroups.includes(identity?.group || '');

  const identityPermissionName = identity?.permissions[0]?.name;
  const identityPermissionValues = identity?.permissions[0]?.values;

  const permissionDisallowed =
    to.meta.auth &&
    isAuth &&
    Array.isArray(to.meta.authorizedPermissions) &&
    to.meta.authorizedPermissions.length > 0 &&
    !(
      to.meta.authorizedPermissions[0].name == identityPermissionName &&
      identityPermissionValues?.includes(to.meta.authorizedPermissions[0].values[0])
    );

  if (groupDisallowed) {
    return next({name: 'order.index'});
  }

  if (
    Array.isArray(to.meta.authorizedGroups) &&
    to.meta.authorizedGroups.length > 0 &&
    to.meta.authorizedGroups.includes('city_manager') &&
    identity?.group == 'city_manager' &&
    permissionDisallowed
  ) {
    return next({name: 'order.index'});
  }

  if (!to.name) {
    return next({name: 'order.index'});
  }

  next();
});

export default router;
