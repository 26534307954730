import { formatError } from '@/utils';
import { defineStore } from 'pinia';
import { useAppStore } from './app';
import sdk from '@/plugins/wl-client';
import { AvailableFilter, SelectedFilter } from '@/components/base/filters/types';
import { GetProductRequestsParams, ProductRequestResponse } from '@winelivery-org/wl-client-sdk-js';
import { useWorkspaceStore } from './workspace';

const STORE_ID = 'products-requests';

export type FilterKeys = 'status' | 'store';

interface State {
  filters: GetProductRequestsParams;
  availableFilters: AvailableFilter<FilterKeys>[];
  selectedFilters: SelectedFilter<FilterKeys>[];
  requests: ProductRequestResponse[];
  count: number;
}

export const useProductsRequestsStore = defineStore(STORE_ID, {
  state: (): State => {
    return {
      filters: { page: 1, limit: 20 },
      selectedFilters: [],
      availableFilters: [
        {
          key: 'status',
          label: 'Status',
          type: 'select',
          options: [
            { label: 'OPEN', value: 'OPEN' },
            { label: 'APPROVED', value: 'APPROVED' },
            { label: 'REJECTED', value: 'REJECTED' },
          ],
        },
        {
          key: 'store',
          label: 'Store',
          type: 'select',
          options: [],
        },
      ],
      count: 0,
      requests: [],
    };
  },
  getters: {
    getFilters: (state) => (): GetProductRequestsParams => {
      const status = state.selectedFilters.find((f) => f.key === 'status');
      const store = state.selectedFilters.find((f) => f.key === 'store');

      return {
        ...state.filters,
        ...(status && { status: status.value as string }),
        ...(store && { storeId: store.value.toString() }),
      };
    },
  },
  actions: {
    async init() {
      const workspace = useWorkspaceStore();
      this.availableFilters = this.availableFilters.map((af) =>
        af.key === 'store'
          ? { ...af, options: workspace.stores.map((s) => ({ label: s.name, value: s.id })) }
          : af
      );

      if (sdk.getIdentity()?.group === 'city_manager') {
        this.selectedFilters.push({
          value: workspace.stores[0].id,
          key: 'store',
        });
      }
      this.getMany();
    },
    setPage(page: number) {
      this.filters.page = page;
      this.getMany();
    },
    reset() {
      this.filters = { page: 1, limit: 20 };
      this.getMany();
    },
    applyFilter(key: FilterKeys, value: string | number) {
      const index = this.selectedFilters.findIndex((f) => f.key === key);
      if (index !== -1) {
        this.selectedFilters[index] = { key, value };
      } else {
        this.selectedFilters.push({ key, value });
      }
      this.getMany();
    },
    resetFilter(key: FilterKeys) {
      this.selectedFilters = this.selectedFilters.filter((f) => f.key !== key);
      this.getMany();
    },
    async getMany() {
      const app = useAppStore();
      try {
        app.loading = true;
        const { storeId, ...filters } = this.getFilters();
        const response =
          sdk.getIdentity()?.group === 'admin'
            ? await sdk.inventory.getProductRequests({ ...filters, storeId })
            : await sdk.inventory.getStoreProductRequests(storeId!, filters);
        this.requests = response.items;
        this.count = response.count;
      } catch (err) {
        formatError(err);
      } finally {
        app.loading = false;
      }
    },
  },
});
