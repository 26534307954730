<template>
  <SidebarLayout :items="items" />
</template>

<script lang="ts" setup>
import {useRoute} from 'vue-router';
import {
  InformationCircleIcon,
  MapPinIcon,
  TableCellsIcon,
  ArrowsRightLeftIcon,
  QueueListIcon,
} from '@heroicons/vue/24/outline';
import SidebarLayout from '../layouts/SidebarLayout.vue';

const route = useRoute();

const items = [
  {
    label: 'Inventario',
    path: `/stores/${route.params.storeId}/inventory`,
    component: TableCellsIcon,
  },
  {
    label: 'Informazioni',
    path: `/stores/${route.params.storeId}`,
    component: InformationCircleIcon,
  },
  {label: 'AZ', path: `/stores/${route.params.storeId}/azs`, component: MapPinIcon},
  {
    label: 'Movimentazioni',
    path: `/stores/${route.params.storeId}/movements`,
    component: ArrowsRightLeftIcon,
  },
  {
    label: 'Aggiornamenti stock',
    path: `/stores/${route.params.storeId}/stock-events`,
    component: QueueListIcon,
  },
];
</script>
