import wlClient from '@/plugins/wl-client';
import {useWorkspaceStore} from '@/stores/workspace';
import {Loader} from '@googlemaps/js-api-loader';
import Cookies from 'js-cookie';
import {defineStore} from 'pinia';

const APP_STORE_ID = 'app';
const REFRESH_TOKEN_COOKIE_KEY = '__refresh_token__';

interface State {
  ready: boolean;
  loading: boolean;
  isMenuVisible: boolean;
}

export const googleApiLoader = new Loader({
  apiKey: 'AIzaSyDpqXvsjO82ASqq9JKK06050u6ldop4z9I',
  libraries: ['drawing', 'geometry', 'places'],
});

export const useAppStore = defineStore(APP_STORE_ID, {
  state: (): State => ({
    loading: false,
    ready: false,
    isMenuVisible: true,
  }),
  actions: {
    async init() {
      await googleApiLoader.load();

      const workspace = useWorkspaceStore();
      try {
        const refreshToken = wlClient.getRefreshToken();
        if (refreshToken) {
          await wlClient.auth.refreshToken();
          Cookies.set(REFRESH_TOKEN_COOKIE_KEY, wlClient.getRefreshToken(), {
            path: '/',
            expires: 365,
          });
          await workspace.init();
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.ready = true;
      }
    },
  },
});
